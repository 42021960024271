import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import { RootState } from 'src/redux/store';
import { ApiCart, ApiProductInCart } from 'src/modules/cart/api/types';
import { useMutateAddToCart } from 'src/modules/cart/api/mutations';
import { useGetActiveCart, useGetCartData } from 'src/modules/cart/api/queries';
import { updateCart } from 'src/redux/cart/cartSlice';
import { useUserContext } from 'src/modules/auth/providers/UserProvider';
import { useAuthContext } from 'src/modules/auth/providers/AuthProvider';
import { ApiOrder } from 'src/modules/myOrders/api/types';
import { User } from 'src/modules/auth/types';
import { useLocationContext } from './LocationProvider';
import { COUNTRIES_OPTIONS } from 'src/modules/address/constants';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from '@tanstack/react-query';
import { ApiError } from 'src/api/types';

interface CartContextValue {
  isLoadingCartData: boolean;
  activeCart: ApiOrder;
  cartDetails: ApiCart;
  mutateDraftCart: (data: any, user: User, addressId?: any) => void;
  refetchCartData: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<ApiCart, ApiError>>;
}

const CartContext = React.createContext(null as CartContextValue);

const CartProvider: React.FC = ({ children }) => {
  const cart = useSelector((state: RootState) => state.products);
  const dispatch = useDispatch();
  const router = useRouter();

  const { instructions } = useUserContext();
  const { userLocation, userCountry, areaPrice } = useLocationContext();
  const { isAuthed } = useAuthContext();

  const productsInCart: ApiProductInCart[] = cart.map(item => ({
    productId: item.id,
    setQty: item.amount,
  }));
  const productId = parseInt(router?.query?.productId as string);
  const isPaymentRoute =
    router.pathname === '/payment' || router.pathname === '/verification';
  const [country] = COUNTRIES_OPTIONS.filter(
    country => country.code === userCountry
  );
  const companyId = country.companyId as number;

  const { mutate: mutateCart, isLoading: isLoadingMutation } =
    useMutateAddToCart();
  const { data: activeCart, isInitialLoading } = useGetActiveCart(
    { product: productId, invalidate: isPaymentRoute },
    {
      enabled: isAuthed,
      cacheTime: 0,
    }
  );
  const {
    data: cartDetails,
    isFetching,
    isInitialLoading: isLoadingCart,
    refetch: refetchCartData,
  } = useGetCartData(
    {
      orderId: activeCart?.orderId,
      locationIds: userLocation?.locationIds,
    },
    {
      enabled: isAuthed && !!activeCart?.orderId,
    }
  );

  const updateOnSuccess = data => {
    if (data?.orderDetails?.cartDetails?.cartLines) {
      const updatedCart = data?.orderDetails?.cartDetails?.cartLines?.map(
        item => ({
          ...item,
          amount: item.quantity,
        })
      );
      dispatch(updateCart(updatedCart));
    }
  };

  const mutateDraftCart = (data, user, addressId) => {
    mutateCart(
      {
        cart: productsInCart,
        saleOrderId: data?.orderId,
        locationId: userLocation?.locationIds,
        customerId: parseInt(user?.id),
        instruction: instructions,
        addressId,
        countryId: userLocation?.country,
        companyId,
        deliveryCharges: areaPrice,
      },
      {
        onSuccess: data => {
          updateOnSuccess(data);
        },
      }
    );
  };

  const isLoadingCartData =
    isInitialLoading || isFetching || isLoadingCart || isLoadingMutation;

  const value = {
    isLoadingCartData,
    activeCart,
    cartDetails,
    mutateDraftCart,
    refetchCartData,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export const useCartContext = (): CartContextValue =>
  React.useContext(CartContext);
export default CartProvider;
